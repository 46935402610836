import * as React from "react";

export const NothingFound: React.FC<{ search: string }> = ({ search }) => (
  <div className="search__products--not-found">
    <p className="u-lead u-lead--bold u-uppercase">
      Sorry, but we couldn’t match any search results for: {search}
    </p>
    <p>
      Try searching for just one part of the name, like "Emrakul".
    </p>
  </div>
);

export default NothingFound;
